import update from 'immutability-helper';
import React from 'react';
import { Mops } from '../types';

const {
  Provider,
  Consumer,
} = React.createContext<Mops.GuidesContext>({
  // tslint:disable-next-line:no-empty
  addGuides: () => {
  },
  guideRequests: [],
  guides: [],
  // tslint:disable-next-line:no-empty
  hideGuides: () => {
  },
  // tslint:disable-next-line:no-empty
  removeGuides: () => {
  },
  // tslint:disable-next-line:no-empty
  showGuides: () => {
  },
  // tslint:disable-next-line:no-empty
  updateGuide: () => {
  },
});

export { Consumer as GuidesConsumer };

export const GuidesProvider: React.FunctionComponent<{
  guideRequests?: Mops.GuideRequest[];
  containerSize: Mops.ContainerSize;
}> = ({
  children,
  guideRequests,
  containerSize,
}) => {
  const [guides, setGuides] = React.useState<Mops.Guide[]>([]);

  const addGuides = (guideModels: Mops.Guide[]) => {
    setGuides(state => {
      const newGuides = guideModels.filter(
        ({ uuid }) => !state.find(guide => guide.uuid === uuid),
      );
      return update(state, {
        $push: newGuides,
      });
    });
  };

  const removeGuides = (uuids?: string[]) => {
    setGuides(state => (uuids
      ? update(state, {
        // TODO investigate this later, implement reduce-based instead of this
        // @ts-ignore
        $splice: uuids
          // .reduce((filteredIds, id) => {
          //   const index = state.findIndex(guide => guide.uuid === id);
          //   return (
          //     index >= 0
          //       ? [...filteredIds, [index, 1]]
          //       : filteredIds
          //   );
          // }, [] as number[][])
          .map((uuid: string) => {
            const index = state.findIndex(guide => guide.uuid === uuid);
            if (index >= 0) {
              return [index, 1];
            }
            return false;
          })
          .filter(Boolean)
          // @ts-ignore
          .sort(([a], [b]) => b - a),
      })
      : []));
  };

  const showGuides = (uuids: string[]) => {
    setGuides(state => update(
      state,
      (uuids || state.map(({ uuid }) => uuid)).reduce((previousValue, currentValue) => {
        const index = state.findIndex(({ uuid }) => uuid === currentValue);
        return {
          ...previousValue,
          [index]: { visible: { $set: true } },
        };
      }, {}),
    ));
  };

  const hideGuides = (uuids?: string[]) => {
    setGuides(state => update(
      state,
      (uuids || state.map(({ uuid }) => uuid)).reduce((previousValue, currentValue) => {
        const index = state.findIndex(({ uuid }) => uuid === currentValue);
        return {
          ...previousValue,
          [index]: { visible: { $set: false } },
        };
      }, {}),
    ));
  };

  const updateGuide = (partialItem: Partial<Mops.Guide>) => {
    setGuides(state => update(state, {
      [state.findIndex(({ uuid }) => uuid === partialItem.uuid)]: {
        $merge: partialItem,
      },
    }));
  };

  // @ts-ignore
  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (guideRequests) {
      const guideModels = guideRequests.map(({
        uuid,
        x,
        y,
      }) => (
        x !== undefined
          ? {
            uuid,
            x1: x,
            x2: x,
            y1: 0,
            y2: containerSize.height,
          }
          : {
            uuid,
            x1: 0,
            x2: containerSize.width,
            y1: y,
            y2: y,
          }
      )) as Mops.Guide[];

      addGuides(guideModels);
      const uuids = guideModels.map(({ uuid }) => uuid);
      return () => {
        removeGuides(uuids);
      };
    }
  }, [guideRequests, containerSize]);

  return (
    <Provider
      value={{
        // @ts-ignore
        guideRequests,
        guides,
        addGuides,
        removeGuides,
        showGuides,
        hideGuides,
        updateGuide,
      }}
    >
      {children}
    </Provider>
  );
};

export const Guides: React.ForwardRefExoticComponent<
Mops.GuideProps & React.RefAttributes<HTMLDivElement>
> = React.forwardRef((
  {
    guideStyle = {},
    guidePosition = {},
    containerSize = {},
    contentRotation = {},
  },
  ref: React.Ref<HTMLDivElement>,
) => {
  const sizeRef = React.useRef<HTMLDivElement>();

  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);

  const { x = 0, y = 0 } = guidePosition;
  const { z = 0 } = contentRotation;
  const { width: containerWidth = 0, height: containerHeight = 0 } = containerSize;

  const viewBox = `0 0 ${containerWidth} ${containerHeight}`;
  const { color = 'hsl(50, 100%, 50%)', width: strokeWidth = 2 } = guideStyle;

  React.useEffect(() => {
    if (sizeRef && sizeRef.current) {
      const {
        clientHeight,
        clientWidth,
      } = sizeRef.current as HTMLDivElement;
      setHeight(clientHeight);
      setWidth(clientWidth);
    }
  }, [ref, setHeight, setWidth]);

  return (
    <div
      ref={ref}
      style={{
        bottom: 0,
        left: 0,
        pointerEvents: 'none',
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 10000,
      }}
    >
      <Consumer>
        {({ guides }) => (
          <div
            ref={sizeRef as React.RefObject<HTMLDivElement>}
            style={{
              width: containerWidth === 0 ? width : containerWidth,
              height: containerHeight === 0 ? height : containerHeight,
              left: containerWidth === width ? 0 : x - containerWidth / 2,
              position: 'absolute',
              top: containerHeight === height ? 0 : y - containerHeight / 2,
              transform: `rotate(${z}deg)`,
            }}
          >
            <svg viewBox={viewBox}>
              {guides
                .filter(({ visible }) => visible)
                .map(({
                  uuid,
                  visible,
                  ...guide
                }) => (
                  <line
                    {...guide}
                    key={uuid}
                    stroke={color}
                    strokeWidth={strokeWidth}
                  />
                ))}
              {/* PLACE FOR CUSTOM GUIDES */}
            </svg>
          </div>
        )}
      </Consumer>
    </div>
  );
});
