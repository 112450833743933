import styles from '../elements.module.scss';

export const resizeClasses = [
  styles.colResize,
  styles.nwseResize,
  styles.rowResize,
  styles.neswResize,
];

export const rotationClasses = [
  styles.eRotate,
  styles.seRotate,
  styles.sRotate,
  styles.swRotate,
  styles.wRotate,
  styles.nwRotate,
  styles.nRotate,
  styles.neRotate,
];
