import React from "react";
import cx from "classnames";
import { resizeClasses, rotationClasses } from "./cursors";
import styles from "./elements.module.scss";
import { Mops } from "./types";
import mergeRefs from "./merge-refs";

export const { Provider: PropProvider, Consumer: PropConsumer } = React.createContext<Mops.ProviderProps>({
  getCursorSlice: (n) => n,
  handleRotationDown: () => undefined,
  isDraggable: false,
  isResizable: false,
  isRotatable: false,
  metaKey: false,
  isChild: false,
  isImage: false,
});

interface HandleLogicProps extends React.HTMLAttributes<HTMLDivElement> {
  cursorSlice?: string;
  outerScale?: number;
}

const HandleLogic: React.ForwardRefExoticComponent<React.PropsWithoutRef<HandleLogicProps> & React.RefAttributes<HTMLDivElement>> = React.forwardRef(({ children, cursorSlice, outerScale, ...props }, ref: React.Ref<HTMLDivElement>) => {
  // TODO PERFORMANCE ISSUE logic inside this effect causes lots of body elem rerenders for just fancy cursor
  // same for a lot cases of handling cursor(and other) visible states that may be simplified in favor to performance
  React.useEffect(() => {
    if (cursorSlice) {
      document.body.classList.remove(...resizeClasses, ...rotationClasses);
      document.body.classList.add(cursorSlice, styles.forceHandle);
    } else {
      document.body.classList.remove(...resizeClasses, ...rotationClasses, styles.forceHandle);
    }
  }, [cursorSlice]);

  const handleRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (handleRef.current !== undefined && handleRef.current !== null && outerScale) {
      const handle = handleRef.current.children[0] as HTMLElement;
      const revertScaleCoef = (1 / outerScale).toString();
      const transformMatrix = getComputedStyle(handle).transform;
      const matrixSeparator = ", ";
      const splittedMatrix = transformMatrix.slice(7, transformMatrix.length - 1).split(matrixSeparator);
      splittedMatrix[0] = revertScaleCoef; // scaleX
      splittedMatrix[3] = revertScaleCoef; // scaleY

      handle.style.transform = `matrix(${splittedMatrix.join(matrixSeparator)})`;
    }
  }, [outerScale]);

  return (
    <div {...props} ref={mergeRefs(handleRef, ref)}>
      {children}
    </div>
  );
});

const HandleBase: React.ForwardRefExoticComponent<React.PropsWithoutRef<Mops.HandleProps> & React.RefAttributes<HTMLDivElement>> = React.forwardRef(
  ({ children, className, onClick, isMouseDown, onMouseDown, variation, outerScale, ...props }, ref: React.Ref<HTMLDivElement>) => {
    const [isDown, setDown] = React.useState(false);
    React.useEffect(() => {
      const handleMouseUp = () => {
        setDown(false);
      };
      window.addEventListener("mouseup", handleMouseUp);
      window.addEventListener("mouseleave", handleMouseUp);
      window.addEventListener("blur", handleMouseUp);
      return () => {
        window.removeEventListener("mouseup", handleMouseUp);
        window.removeEventListener("mouseleave", handleMouseUp);
        window.removeEventListener("blur", handleMouseUp);
      };
    }, [setDown]);
    const handleClick = React.useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (typeof onClick === "function") {
          onClick(e);
        }
      },
      [onClick]
    );
    return (
      <PropConsumer>
        {({ handleRotationDown, isResizable, isRotatable, getCursorSlice, metaKey }) => {
          const cursorSlice = getCursorSlice(Mops.HandleVariations[variation]);
          const rotationClassName = rotationClasses[cursorSlice];
          const resizeClassName = resizeClasses[cursorSlice % resizeClasses.length];

          return (
            <HandleLogic
              {...props}
              className={cx(className, styles.handleBase, {
                [resizeClassName]: !metaKey && isResizable,
                [rotationClassName]: metaKey && isRotatable,
              })}
              ref={ref}
              onClick={handleClick}
              cursorSlice={isDown ? (metaKey ? rotationClassName : resizeClassName) : undefined}
              onMouseDown={(event: React.MouseEvent<HTMLDivElement>) => {
                setDown(true);
                metaKey ? handleRotationDown(event) : onMouseDown && onMouseDown(event);
              }}
              outerScale={outerScale}>
              {children}
            </HandleLogic>
          );
        }}
      </PropConsumer>
    );
  }
);

export const Handle: React.ForwardRefExoticComponent<React.PropsWithoutRef<Mops.HandleProps & { variation: Mops.HandleVariation; full?: boolean }> & React.RefAttributes<HTMLDivElement>> = React.forwardRef(
  ({ className, variation, isMouseDown, style, marker: Marker, full, outerScale, ...props }, ref) => (
    <HandleBase
      id="zuuvi-mops-handle"
      {...props}
      variation={variation}
      isMouseDown={isMouseDown}
      className={cx(className, styles[variation], {
        [styles.full]: full,
      })}
      ref={ref}
      outerScale={outerScale}>
      {Marker && <Marker />}
    </HandleBase>
  )
);

// const HandleMarker: React.FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = (
//   { children, className, ...props },
// ) => (
//   <span {...props} className={cx(className, styles.handleMarker)}>
//     {children}
//   </span>
// );
//
// Handle.defaultProps = {
//   marker: HandleMarker,
// };

export const Handles: React.FunctionComponent<React.HTMLAttributes<HTMLDivElement> & { draw?: boolean }> = ({ children, className, draw, ...props }) => (
  <div
    {...props}
    className={cx(className, styles.handles, {
      [styles.drawOutline]: draw,
    })}>
    {children}
  </div>
);

// @ts-ignore
export const Wrapper: React.ForwardRefRenderFunction<HTMLElement, Mops.WrapperProps> = React.forwardRef(({ children, className, isDown, as: As, ...props }, ref: React.Ref<HTMLElement>) => (
  <As {...props} ref={ref as React.Ref<HTMLElement>} className={cx(className, styles.wrapper)}>
    {children}
  </As>
));

export const Content: React.ForwardRefExoticComponent<Mops.ContentProps & React.RefAttributes<HTMLElement>> = React.forwardRef(({ children, className, onMouseDown, ...props }, ref: React.Ref<HTMLDivElement>) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div
    {...props}
    onMouseDown={onMouseDown}
    ref={ref as React.Ref<HTMLDivElement>}
    className={cx(className, styles.content, {
      [styles.move]: typeof onMouseDown === "function",
    })}>
    {children}
  </div>
));

export const BoundingBox: React.ForwardRefExoticComponent<React.PropsWithoutRef<React.HTMLAttributes<HTMLDivElement> & { draw?: boolean }> & React.RefAttributes<HTMLDivElement>> = React.forwardRef(
  ({ children, className, draw, ...props }, ref: React.Ref<HTMLDivElement>) => (
    <div
      {...props}
      ref={ref as React.Ref<HTMLDivElement>}
      className={cx(className, styles.boundingBox, {
        [styles.drawOutline]: draw,
      })}>
      {children}
    </div>
  )
);
