import React from 'react';

export const useDrag = ({
  loaded, isDown, handleDragStart
}: {
  loaded: boolean,
  isDown: boolean,
  handleDragStart: () => void,
}) => React.useEffect(() => {
  if (loaded) {
    if (isDown) {
      handleDragStart();
    }
  }
}, [isDown]);

// TODO
export const useDown = ({
  isRotationDown,
  isTopDown,
  isRightDown,
  isBottomDown,
  isLeftDown,
  isTopLeftDown,
  isTopRightDown,
  isBottomLeftDown,
  isBottomRightDown,
  loaded,
  isDown,
  handleDragStart,
  handleDragEnd,
  handleResizeStart,
  handleRotateStart,
  handleRotateEnd,
  handleResizeEnd,
  metaKey,
}: {
  handleDragStart: () => void,
  handleDragEnd: () => void,
  handleResizeStart: () => void,
  handleRotateStart: () => void,
  handleRotateEnd: () => void,
  handleResizeEnd: () => void,
  metaKey: boolean,
  loaded: boolean,
  isDown: boolean,
  isTopDown: boolean,
  isRightDown: boolean,
  isBottomDown: boolean,
  isLeftDown: boolean,
  isTopLeftDown: boolean,
  isTopRightDown: boolean,
  isBottomLeftDown: boolean,
  isBottomRightDown: boolean,
  isRotationDown: boolean,
}) => {
  React.useEffect(() => {
    if (loaded) {
      if (isDown) {
        handleDragStart();
      } else {
        handleDragEnd();
      }
    }
  }, [isDown]);
  React.useEffect(() => {
    if (loaded) {
      if (
        [
          isTopDown,
          isRightDown,
          isBottomDown,
          isLeftDown,
          isTopLeftDown,
          isTopRightDown,
          isBottomLeftDown,
          isBottomRightDown,
        ].filter(Boolean).length
      ) {
        handleResizeStart();
      } else if (isRotationDown) {
        handleRotateStart();
      } else if (metaKey) {
        handleRotateEnd();
      } else {
        handleResizeEnd();
      }
    }
  }, [
    isRotationDown,
    isTopDown,
    isRightDown,
    isBottomDown,
    isLeftDown,
    isTopLeftDown,
    isTopRightDown,
    isBottomLeftDown,
    isBottomRightDown,
  ]);
};
