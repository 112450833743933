import React from 'react';
import cn from 'classnames';

import { Mops } from './types';
import styles from './elements.module.scss';

// eslint-disable-next-line import/prefer-default-export
export const Container = ({ children, grid, classNames = {} }: Mops.ContainerProps) => {
  const { container = '', customGrid = '' } = classNames;

  return (
    <div
      className={cn(container, styles.container, {
        [styles.withGrid]: !!grid,
        [customGrid]: !!grid,
      })}
      style={{ backgroundSize: `${grid}px ${grid}px` }}
    >
      {children}
    </div>
  );
};
