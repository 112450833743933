import React from "react";
import { Mops } from "../types";
import { useMouseMove } from "./mouse-event-hooks";

/**
 * @param options
 * @param options.setSize
 * @param options.setInitialSize
 * @param options.setPosition
 * @param options.setInitialPosition
 * @param options.handleSize
 * @param options.handlePosition
 * @param options.scale
 * @param options.rotation
 */
export const useHandle = ({
  setSize,
  setInitialSize,
  setPosition,
  setInitialPosition,
  handleSize,
  handlePosition,
  scale,
  rotation,
  parentRotation,
}: Mops.UseHandleProps): [
  boolean,
  (e: React.MouseEvent<HTMLElement>) => void
] => {
  const [isDown, setDown] = useMouseMove({
    onMouseUp: (
      position: Mops.PositionModel,
      altKey: boolean,
      shiftKey: boolean
    ) => {
      const nextSize = handleSize(position, altKey, shiftKey);
      setSize(nextSize);
      setInitialSize(nextSize);
      const nextPosition = handlePosition(position, altKey, shiftKey);
      setPosition(nextPosition);
      setInitialPosition(nextPosition);
    },
    onMouseMove: (
      position: Mops.PositionModel,
      altKey: boolean,
      shiftKey: boolean
    ) => {
      const nextSize = handleSize(position, altKey, shiftKey);
      setSize(nextSize);
      const nextPosition = handlePosition(position, altKey, shiftKey);
      setPosition(nextPosition);
    },
    onMouseDown: () => {},
    scale,
    rotation,
    parentRotation,
  });
  return [isDown, setDown] as [
    boolean,
    (e: React.MouseEvent<HTMLElement>) => void
  ];
};

export const useHandles = ({
  setBottomRightDown,
  setTopLeftDown,
  setBottomLeftDown,
  setTopRightDown,
  setLeftDown,
  setBottomDown,
  setRightDown,
  setTopDown,
}: {
  setBottomRightDown: (event: React.MouseEvent<HTMLElement>) => void;
  setTopLeftDown: (event: React.MouseEvent<HTMLElement>) => void;
  setBottomLeftDown: (event: React.MouseEvent<HTMLElement>) => void;
  setTopRightDown: (event: React.MouseEvent<HTMLElement>) => void;
  setLeftDown: (event: React.MouseEvent<HTMLElement>) => void;
  setBottomDown: (event: React.MouseEvent<HTMLElement>) => void;
  setRightDown: (event: React.MouseEvent<HTMLElement>) => void;
  setTopDown: (event: React.MouseEvent<HTMLElement>) => void;
}): Mops.HandleProps[] =>
  React.useMemo(
    () => [
      {
        onMouseDown: setTopDown,
        variation: "n",
      },
      {
        onMouseDown: setRightDown,
        variation: "e",
      },
      {
        onMouseDown: setBottomDown,
        variation: "s",
      },
      {
        onMouseDown: setLeftDown,
        variation: "w",
      },
      {
        onMouseDown: setTopRightDown,
        variation: "ne",
      },
      {
        onMouseDown: setBottomRightDown,
        variation: "se",
      },
      {
        onMouseDown: setBottomLeftDown,
        variation: "sw",
      },
      {
        onMouseDown: setTopLeftDown,
        variation: "nw",
      },
    ],
    [
      setBottomRightDown,
      setTopLeftDown,
      setBottomLeftDown,
      setTopRightDown,
      setLeftDown,
      setBottomDown,
      setRightDown,
      setTopDown,
    ]
  );

/*
 *
 *
 * {items.map(item => <button
 *   onClick={() => setType(item.platform)}
 *   className={item.platform === type ? 'active' : ''}
 * >{item.platform}</button>
 * )}
 *
 * accordion
 * {items[type].map(item => <Tab data={item}/>)}
 *
 *
 */
