import React, { useEffect, useState } from "react";
import {
  BoundingBox,
  Content,
  Handle,
  Handles,
  PropProvider,
  Wrapper,
} from "./elements";
import {
  listenRR,
  useCursorSlice,
  useDown,
  useDrag,
  useHandleMouse,
  useHandleMouseEvent,
  useHandlers,
  useHandles,
  useHandlesDown,
  useInitialSize,
  useLoaded,
  useMeta,
  useWithCornerHandle,
  useWithDown,
  useWithHandle,
} from "./hooks";
import { Mops } from "./types";
import { getBoundingBox } from "./utils";

// const getTopLeftFromCenterPosition = (position: Mops.PositionModel, size: Mops.SizeModel): Mops.PositionModel => {
//   const { x, y } = position;
//   const { width, height } = size;
//   const minXPosition = width / 2;
//   const minYPosition = height / 2;

//   const newPosition = {
//     x: minXPosition >= x ? minXPosition : x,
//     y: minYPosition >= y ? minYPosition : y,
//   };

//   return newPosition;
// };

// eslint-disable-next-line import/prefer-default-export
export const Box: React.ForwardRefExoticComponent<
  // seems like both ways correct typing
  React.PropsWithoutRef<Mops.BoxProps & Mops.GuidesContext> &
    React.RefAttributes<unknown>
> = React.forwardRef(
  (
    {
      id,
      as,
      isChild,
      isImage,
      children,
      className,
      drawBoundingBox,
      drawBox,
      isResizable,
      isRotatable,
      isDraggable,
      fullHandles,
      marker,
      minHeight,
      minWidth,
      onDrag,
      onDragStart,
      onDragEnd,
      onResize,
      onResizeStart,
      onResizeEnd,
      onRotate,
      onRotateStart,
      onRotateEnd,
      onMouseDown,
      position,
      rotation,
      parentRotation,
      scale,
      outerScale,
      showGuides,
      hideHandles = false,
      hideGuides,
      updateGuide,
      addGuides,
      removeGuides,
      guides,
      guideRequests,
      shouldSnap,
      size,
      style,
      isEditableText,
    },
    ref
  ) => {
    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
    const contentRef =
      React.useRef<HTMLElement>() as React.RefObject<HTMLElement>;
    const [loaded, setLoaded] = React.useState(false);
    const [initialSize, setInitialSize] = React.useState<Mops.SizeModel>(
      size as Mops.SizeModel
    );
    const [currentSize, setSize] = React.useState<Mops.SizeModel>(initialSize);
    const [initialPosition, setInitialPosition] =
      React.useState<Mops.PositionModel>(
        position
        // getTopLeftFromCenterPosition(position, initialSize),
      );
    const [currentPosition, setPosition] =
      React.useState<Mops.PositionModel>(initialPosition);
    const [initialRotation, setInitialRotation] =
      React.useState<Mops.RotationModel>(rotation);
    const [currentRotation, setRotation] =
      React.useState<Mops.RotationModel>(initialRotation);
    const [additionalAngle, setAdditionalAngle] =
      React.useState<Mops.RotationModel>(rotation);

    useEffect(() => {
      if (!isInitialRender) {
        const newSize = size as Mops.SizeModel;
        // const newPosition = getTopLeftFromCenterPosition(position, newSize);

        setInitialSize(newSize);
        setSize(newSize);
        setInitialPosition(position);
        setPosition(position);
        setInitialRotation(rotation);
        setRotation(rotation);
        setAdditionalAngle(rotation);
      } else {
        setIsInitialRender(false);
      }
    }, [size, rotation, position]);

    const metaKey = useMeta();
    const {
      handleDrag,
      handleDragEnd,
      handleDragStart,
      handleResize,
      handleResizeEnd,
      handleResizeStart,
      handleRotate,
      handleRotateEnd,
      handleRotateStart,
      handleMouseDown,
    } = useHandlers({
      currentPosition,
      currentRotation,
      currentSize,
      id,
      isImage,
      onDrag,
      onDragEnd,
      onDragStart,
      onResize,
      onResizeEnd,
      onResizeStart,
      onRotate,
      onRotateEnd,
      onRotateStart,
      onMouseDown,
    });
    const withHandle = useWithHandle({
      contentRef,
      currentPosition,
      currentRotation,
      initialPosition,
      initialSize,
      isResizable,
      isImage,
      // @ts-ignore not implemented yet
      minHeight,
      // @ts-ignore not implemented yet
      minWidth,
      // @ts-ignore not implemented yet
      scale,
      setInitialPosition,
      setInitialSize,
      setPosition,
      setSize,
      parentRotation,
    });

    const withCornerHandle = useWithCornerHandle({
      currentRotation,
      initialPosition,
      initialSize,
      withHandle,
    });

    const {
      isBottomDown,
      isBottomLeftDown,
      isBottomRightDown,
      isLeftDown,
      isRightDown,
      isTopDown,
      isTopLeftDown,
      isTopRightDown,
      setBottomDown,
      setBottomLeftDown,
      setBottomRightDown,
      setLeftDown,
      setRightDown,
      setTopDown,
      setTopLeftDown,
      setTopRightDown,
    } = useHandlesDown({
      currentRotation,
      initialPosition,
      initialSize,
      // @ts-ignore
      withCornerHandle,
      withHandle,
    });
    const handleMouse = useHandleMouse({
      addGuides,
      currentRotation,
      currentSize,
      guideRequests,
      guides,
      hideGuides,
      initialPosition,
      removeGuides,
      shouldSnap,
      showGuides,
      updateGuide,
    });
    const handleMouseEvent = useHandleMouseEvent({
      additionalAngle,
      contentRef,
      initialRotation,
      isRotatable,
    });
    const { handleRotationDown, isDown, isRotationDown, setDown } = useWithDown(
      {
        handleMouse,
        handleMouseEvent,
        handleMouseDown,
        hideGuides,
        // @ts-ignore not implemented yet
        scale,
        setAdditionalAngle,
        setInitialPosition,
        setInitialRotation,
        setPosition,
        setRotation,
        rotation: currentRotation,
        parentRotation,
      }
    );
    const getCursorSlice = useCursorSlice(currentRotation);
    const handles = useHandles({
      setBottomDown,
      setBottomLeftDown,
      setBottomRightDown,
      setLeftDown,
      setRightDown,
      setTopDown,
      setTopLeftDown,
      setTopRightDown,
    });

    // we want offset initial point to be mentally center, not top left corner, so we use it like it is so, with offset included
    const initialOffsetHeight = (currentSize.height || 0) / 2;
    const initialOffsetWidth = (currentSize.width || 0) / 2;

    // seems redundant since GreenSock doesn't seem to replace current transform, only patching
    const originalTransform = ""; // contentRef?.current ? getComputedStyle(contentRef?.current).transform : '';
    // console.log('Current box:', contentRef, { originalTransform });

    const wrapperStyle = {
      ...currentSize,
      top: `${currentPosition.y - initialOffsetHeight}px`,
      left: `${currentPosition.x - initialOffsetWidth}px`,
      // transform: `translate3d(${currentPosition.x}px, ${currentPosition.y}px, 0) translate3d(-50%, -50%, 0)`,
    };
    const boxStyle = {
      ...getBoundingBox({
        ...currentSize,
        angle: currentRotation.z,
      }),
    };
    const contentStyle = {
      ...currentSize,
      transform: isEditableText
        ? "none"
        : `${originalTransform} rotate3d(0, 0, 1, ${currentRotation.z}deg)`,
    };
    useInitialSize({ contentRef, setInitialSize, setSize });
    listenRR({
      currentPosition,
      currentRotation,
      currentSize,
      handleDrag,
      handleResize,
      handleRotate,
      isBottomDown,
      isBottomLeftDown,
      isBottomRightDown,
      isDown,
      isLeftDown,
      isRightDown,
      isRotationDown,
      isTopDown,
      isTopLeftDown,
      isTopRightDown,
      loaded,
    });
    useDown({
      handleDragEnd,
      handleDragStart,
      handleResizeEnd,
      handleResizeStart,
      handleRotateEnd,
      handleRotateStart,
      isBottomDown,
      isBottomLeftDown,
      isBottomRightDown,
      isDown,
      isLeftDown,
      isRightDown,
      isRotationDown,
      isTopDown,
      isTopLeftDown,
      isTopRightDown,
      loaded,
      metaKey,
    });
    useDrag({
      loaded,
      isDown,
      handleDragStart,
    });
    useLoaded(setLoaded);

    return (
      <Wrapper
        ref={ref as React.Ref<HTMLElement>}
        as={as}
        style={{ ...(style || {}), ...wrapperStyle }}
        isDown={isDown}
        className={className}
      >
        <BoundingBox style={boxStyle} draw={drawBoundingBox} />
        <Content
          ref={contentRef as React.Ref<HTMLDivElement>}
          style={contentStyle}
          onMouseDown={
            isChild
              ? metaKey && isDraggable
                ? setDown
                : undefined
              : !metaKey && isDraggable
              ? setDown
              : undefined
          }
        >
          {children}
        </Content>
        {(isResizable || isRotatable) && !hideHandles && (
          <PropProvider
            value={{
              getCursorSlice,
              handleRotationDown,
              isDraggable,
              isResizable,
              isRotatable,
              metaKey,
              isImage,
            }}
          >
            <Handles style={contentStyle} draw={drawBox}>
              {handles.map((handle) => (
                <Handle
                  key={handle.variation}
                  {...handle}
                  marker={marker}
                  full={fullHandles}
                  outerScale={outerScale}
                />
              ))}
            </Handles>
          </PropProvider>
        )}
      </Wrapper>
    );
  }
);

// @ts-ignore
Box.defaultProps = {
  as: "div",
  drawBoundingBox: false,
  drawBox: true,
  minHeight: 40,
  minWidth: 40,
  position: {
    x: 0,
    y: 0,
  },
  rotation: {
    x: 0,
    y: 0,
    z: 0,
  },
  scale: 1,
  shouldSnap: [],
  size: {
    height: "auto",
    width: "auto",
  },
};
