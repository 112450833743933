import React, { useState, useEffect, useRef } from "react";
import update from "immutability-helper";
import cn from "classnames";

import {
  closest,
  getOffsetRect,
  getTransformProps,
  listWithChildren,
  getItemChildrenId,
} from "../utils";
import NestableItem from "./NestableItem";

import {
  IncomingProps,
  Item,
  NestableState,
  ElementCopyStyles,
  MoveItemProps,
  MoveItemExtraProps,
  GetSplicePathOptions,
  Mouse,
} from "./interface";
import "./Nestable.css";

const groupHash = Math.random().toString(36).slice(2);

const Nestable = (props: IncomingProps) => {
  const {
    items: itemsFromProps = [],
    threshold = 30,
    maxDepth = 10,
    collapsed = false,
    isDisabled,
    group = groupHash,
    childrenProp = "children",
    classNames = {},
    renderItem = ({ item }) => item.toString(),
    onChange = () => {},
    onCollapse = () => {},
    confirmChange = () => true,
    onGroup = () => {},
    selectedLayers
  } = props;

  const [state, updateState] = useState<NestableState>({
    items: [],
    itemsOld: null, // snap copy in case of canceling drag
    dragItem: null,
    isDirty: false,
    collapsedGroups: [],
    isShiftPressed: false,
    selectedItems: {},
  });

  const stateRef = useRef<NestableState>(state);

  const { items, dragItem } = state;

  const elementRef = useRef<Item | null>(null);
  const elementHeightRef = useRef<number>(0);
  const elCopyStylesRef = useRef<ElementCopyStyles | null>(null);
  const mouseRef = useRef<Mouse>({
    last: { x: 0 },
    shift: { x: 0 },
  });
  const rootContainerRef = useRef<HTMLDivElement | null>(null);
  const intervalIdRef = useRef<any>();

  const setState = (currentState: NestableState) => {
    stateRef.current = currentState;
    updateState(currentState);
  };

  // AUTOSCROLL METHODS
  const scroll = (step: number) => {
    rootContainerRef.current?.scrollTo(
      0,
      rootContainerRef.current.scrollTop + step
    );
  };

  const setScroll = (isToTop: boolean) => {
    const step = 0.01;
    let initialValue = isToTop ? 0 : 1;
    clearInterval(intervalIdRef.current);

    intervalIdRef.current = setInterval(() => {
      if (isToTop) {
        initialValue -= step;
      } else {
        initialValue += step;
      }
      scroll(initialValue);
    }, 0);
  };

  const disableScroll = () => {
    clearInterval(intervalIdRef.current);
  };

  const calculateScroll = (clientY: number) => {
    if (rootContainerRef.current) {
      const {
        clientHeight = 0,
        scrollTop = 0,
        scrollHeight,
      } = rootContainerRef.current;

      const containerOffset = getOffsetRect(rootContainerRef.current);

      const offsetForScrolling = elementHeightRef.current * 1.5;
      const isBottomLimit = scrollHeight - scrollTop === clientHeight;
      const isTopLimit = scrollTop === 0;
      const shouldScrollDown =
        !isBottomLimit &&
        containerOffset.top + clientHeight < clientY + offsetForScrolling;
      const shouldScrollUp =
        !isTopLimit && containerOffset.top + offsetForScrolling > clientY;
      const shouldScroll = shouldScrollDown || shouldScrollUp;

      shouldScroll ? setScroll(shouldScrollUp) : disableScroll();
    }
  };

  // @ts-ignore
  // eslint-disable-next-line
  const isCollapsed = (item: Item | null) =>
    !!(stateRef.current.collapsedGroups.indexOf(item?.id) > -1 !== collapsed);

  const getPathById = (id: string, currentItems = stateRef.current.items) => {
    let path: Array<number> = [];

    currentItems.every((item, i) => {
      if (item.id === id) {
        path.push(i);
      } else if (item[childrenProp]) {
        const childrenPath = getPathById(id, item[childrenProp]);

        if (childrenPath.length) {
          path = path.concat(i).concat(childrenPath);
        }
      }

      return path.length === 0;
    });

    return path;
  };

  const getItemByPath = (
    path: Array<number>,
    currentItems = stateRef.current.items
  ): Item | null => {
    let item: Item | null = null;

    path.forEach((index: number) => {
      const list = item ? item[childrenProp] : currentItems;
      item = list[index];
    });

    return item;
  };

  const getItemDepth = (item: Item) => {
    let level = 1;

    if (item[childrenProp].length > 0) {
      const childrenDepths = item[childrenProp].map(getItemDepth);
      level += Math.max(...childrenDepths);
    }

    return level;
  };

  const getSplicePath = (
    path: Array<number>,
    options: GetSplicePathOptions = {}
  ) => {
    const splicePath: any = {};
    const numToRemove = options.numToRemove || 0;
    const itemsToInsert = options.itemsToInsert || [];
    const lastIndex = path.length - 1;
    let currentPath = splicePath;

    path.forEach((index, i) => {
      if (i === lastIndex) {
        currentPath.$splice = [[index, numToRemove, ...itemsToInsert]];
      } else {
        const nextPath = {};
        if (options.childrenProp) {
          currentPath[index] = { [options.childrenProp]: nextPath };
        }
        currentPath = nextPath;
      }
    });

    return splicePath;
  };

  const getRealNextPath = (
    prevPath: Array<number>,
    nextPath: Array<number>,
    dragItemSize: number
  ): Array<number> => {
    const ppLastIndex = prevPath.length - 1;
    const npLastIndex = nextPath.length - 1;
    const newDepth = nextPath.length + dragItemSize - 1;

    if (prevPath.length < nextPath.length) {
      // move into depth
      let wasShifted = false;

      // if new depth exceeds max, try to put after item instead of into item
      if (newDepth > maxDepth && nextPath.length) {
        return getRealNextPath(prevPath, nextPath.slice(0, -1), dragItemSize);
      }

      return nextPath.map((nextIndex: number, i: number) => {
        if (wasShifted) {
          return i === npLastIndex ? nextIndex + 1 : nextIndex;
        }

        if (typeof prevPath[i] !== "number") {
          return nextIndex;
        }

        if (nextPath[i] > prevPath[i] && i === ppLastIndex) {
          wasShifted = true;
          return nextIndex - 1;
        }

        return nextIndex;
      });
    }
    if (prevPath.length === nextPath.length) {
      // if move bottom + move to item with children --> make it a first child instead of swap
      if (nextPath[npLastIndex] > prevPath[npLastIndex]) {
        const target = getItemByPath(nextPath);

        if (target) {
          if (
            newDepth < maxDepth &&
            target[childrenProp] &&
            // @ts-ignore
            target[childrenProp].length &&
            !isCollapsed(target)
          ) {
            return nextPath
              .slice(0, -1)
              .concat(nextPath[npLastIndex] - 1)
              .concat(0);
          }
        }
      }
    }

    return nextPath;
  };

  const onToggleCollapse = (item: Item, isGetter: boolean) => {
    const isCollapse = isCollapsed(item);

    const newCollapsedGroups = {
      // @ts-ignore
      // eslint-disable-next-line
      collapsedGroups:
        isCollapse !== collapsed
          ? stateRef.current.collapsedGroups.filter((id) => id !== item.id)
          : stateRef.current.collapsedGroups.concat(item.id),
    };

    if (isGetter) {
      return newCollapsedGroups;
    }

    if (!isCollapse && !isGetter) {
      const collapsedItems = getItemChildrenId(item);
      onCollapse({ collapsedItems });
    }

    setState({ ...stateRef.current, ...newCollapsedGroups });

    return {};
  };

  const moveItem = (
    { currentDragItem, pathFrom, pathTo }: MoveItemProps,
    extraProps: MoveItemExtraProps = {}
  ) => {
    const dragItemSize = getItemDepth(currentDragItem);

    // the remove action might affect the next position,
    // so update next coordinates accordingly

    const realPathTo = getRealNextPath(pathFrom, pathTo, dragItemSize);

    if (realPathTo.length === 0) return;

    // user can validate every movement
    const destinationPath =
      realPathTo.length > pathTo.length ? pathTo : pathTo.slice(0, -1);
    const destinationParent = getItemByPath(destinationPath);
    if (!confirmChange(currentDragItem, destinationParent)) return;

    const removePath = getSplicePath(pathFrom, {
      numToRemove: 1,
      childrenProp,
    });

    const insertPath = getSplicePath(realPathTo, {
      numToRemove: 0,
      itemsToInsert: [currentDragItem],
      childrenProp,
    });

    const itemsWithRemovePath = update(stateRef.current.items, removePath);
    const finalItems = update(itemsWithRemovePath, insertPath);

    setState({
      ...stateRef.current,
      items: finalItems,
      isDirty: true,
      ...extraProps,
    });
  };

  const tryIncreaseDepth = (currentDragItem: Item) => {
    if (!currentDragItem) return;

    const pathFrom: Array<number> = getPathById(currentDragItem.id);
    const itemIndex = pathFrom[pathFrom.length - 1];
    const newDepth = pathFrom.length + getItemDepth(currentDragItem);

    // has previous sibling and isn't at max depth
    if (itemIndex > 0 && newDepth <= maxDepth) {
      const prevSibling = getItemByPath(
        pathFrom.slice(0, -1).concat(itemIndex - 1)
      );

      // previous sibling is not collapsed
      if (prevSibling) {
        // @ts-ignore
        if (!prevSibling[childrenProp].length || !isCollapsed(prevSibling)) {
          const pathTo: Array<number> = pathFrom
            .slice(0, -1)
            .concat(itemIndex - 1)
            // @ts-ignore
            .concat(prevSibling[childrenProp].length);

          // if collapsed by default
          // and was no children here
          // open this node
          let collapseProps: NestableState | {} = {};
          // @ts-ignore
          if (collapsed && !prevSibling[childrenProp].length) {
            collapseProps = onToggleCollapse(prevSibling, true);
          }

          moveItem({ currentDragItem, pathFrom, pathTo }, collapseProps);
        }
      }
    }
  };

  const tryDecreaseDepth = (currentDragItem: Item) => {
    if (!currentDragItem) return;

    const pathFrom = getPathById(currentDragItem.id);

    // has parent
    if (pathFrom && pathFrom.length > 1) {
      const itemIndex = pathFrom[pathFrom.length - 1];
      const parent: Item | null = getItemByPath(pathFrom.slice(0, -1));

      // is last (by order) item in array
      if (parent) {
        if (itemIndex + 1 === parent[childrenProp].length) {
          const pathTo = pathFrom.slice(0, -1);
          pathTo[pathTo.length - 1] += 1;

          // if collapsed by default
          // and is last (by count) item in array
          // remove this node from list of open nodes
          let collapseProps = {};
          if (collapsed && parent[childrenProp].length === 1) {
            collapseProps = onToggleCollapse(parent, true);
          }

          moveItem({ currentDragItem, pathFrom, pathTo }, collapseProps);
        }
      }
    }
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.which === 27) {
      // ESC
      // eslint-disable-next-line
      onDragEnd(null, true);
    }
  };

  const onMouseMove = (e: MouseEvent, dragItemOld: any) => {
    const currentDragItem = dragItemOld || stateRef.current.dragItem;

    const { clientX, clientY } = e;
    const transformProps = getTransformProps(clientX, clientY);
    const elCopy: HTMLOListElement | null = document.querySelector(
      `.nestable-${group} .nestable-drag-layer > .nestable-list`
    );

    calculateScroll(clientY);

    if (!elCopyStylesRef.current) {
      const offset = getOffsetRect(elementRef.current);

      elCopyStylesRef.current = {
        marginTop: offset.top - clientY,
        marginLeft: offset.left - clientX,
        ...transformProps,
      };
    } else {
      elCopyStylesRef.current = {
        ...elCopyStylesRef.current,
        ...transformProps,
      };

      Object.keys(transformProps).forEach((key: string) => {
        // eslint-disable-next-line
        if (transformProps.hasOwnProperty(key) && elCopy) {
          // @ts-ignore
          elCopy.style[key] = transformProps[key];
        }
      });

      // on the first touch last x is 0 and in some situations trigers autonesting
      const isInitialLastX = mouseRef.current.last.x === 0;
      const subtractor = isInitialLastX ? clientX : mouseRef.current.last.x;

      const diffX = clientX - subtractor;

      if (
        (diffX >= 0 && mouseRef.current.shift.x >= 0) ||
        (diffX <= 0 && mouseRef.current.shift.x <= 0)
      ) {
        mouseRef.current.shift.x += diffX;
      } else {
        mouseRef.current.shift.x = 0;
      }
      mouseRef.current.last.x = clientX;

      if (Math.abs(mouseRef.current.shift.x) > threshold) {
        if (mouseRef.current.shift.x > 0) {
          tryIncreaseDepth(currentDragItem);
        } else {
          tryDecreaseDepth(currentDragItem);
        }

        mouseRef.current.shift.x = 0;
      }
    }
  };

  function startTrackMouse() {
    // @ts-ignore
    document.addEventListener("mousemove", onMouseMove);
    // @ts-ignore
    // eslint-disable-next-line
    document.addEventListener("mouseup", onDragEnd);
    document.addEventListener("keydown", onKeyDown);
  }

  function stopTrackMouse() {
    // @ts-ignore
    document.removeEventListener("mousemove", onMouseMove);
    // @ts-ignore
    // eslint-disable-next-line
    document.removeEventListener("mouseup", onDragEnd);
    document.removeEventListener("keydown", onKeyDown);
    elCopyStylesRef.current = null;
  }

  const dragApply = () => {
    const shouldCallOnChange = stateRef.current.isDirty && onChange;
    const {
      current: {
        items: currentItems,
        dragItem: currentDragItem,
        itemsOld: currentItemsOld,
      },
    } = stateRef;

    setState({
      ...stateRef.current,
      itemsOld: null,
      dragItem: null,
      isDirty: false,
    });

    shouldCallOnChange &&
      onChange(currentItems, currentDragItem, currentItemsOld);
  };

  const dragRevert = () => {
    setState({
      ...stateRef.current,
      items: stateRef.current.itemsOld || [],
      itemsOld: null,
      dragItem: null,
      isDirty: false,
    });
  };

  function onDragEnd(e: DragEvent | null, isCancel: boolean = false) {
    disableScroll();
    e && e.preventDefault();

    stopTrackMouse();
    elementRef.current = null;
    elementHeightRef.current = 0;

    isCancel ? dragRevert() : dragApply();
  }

  const handleItemClick = (currentItem: Item, index: number) => {
    const { current: currentState } = stateRef;
    const { selectedItems: oldSelectedItems } = currentState;

    const selectedItems = { ...oldSelectedItems };

    const clearChildren = (children: Array<Item>) => {
      children.forEach((child: Item) => {
        if (oldSelectedItems[child.id]) {
          delete selectedItems[child.id];
        }
        child[childrenProp].length && clearChildren(child[childrenProp]);
      });
    };

    if (oldSelectedItems[currentItem.id]) {
      delete selectedItems[currentItem.id];
    } else {
      currentItem[childrenProp].length &&
        clearChildren(currentItem[childrenProp]);

      selectedItems[currentItem.id] = { item: currentItem, index };
    }

    const orderedItems = Object.values(selectedItems)
      .sort((current, next) => current.index - next.index)
      .map(({ item }) => item);

    onGroup(orderedItems);
    setState({ ...currentState, selectedItems });
  };

  const handleShiftPress = (e: KeyboardEvent) => {
    const { keyCode } = e;
    const { current: currentState } = stateRef;
    const { isShiftPressed } = currentState;
    // 16 - shift code
    if (keyCode === 16 && !isShiftPressed) {
      setState({ ...currentState, isShiftPressed: true });
    }
  };

  const handleShiftUp = (e: KeyboardEvent) => {
    const { keyCode } = e;
    if (keyCode === 16) {
      setState({
        ...stateRef.current,
        isShiftPressed: false
      });
    }
  };

  useEffect(() => {
    if(!selectedLayers || selectedLayers && selectedLayers.length === 0) {
      setState({
        ...stateRef.current,
        selectedItems: {}
      });
    }
    
  }, [selectedLayers]);

  useEffect(() => {
    let { items: incomingItems } = props;

    // make sure every item has property 'children'
    incomingItems = listWithChildren(items, childrenProp);

    setState({ ...state, items: incomingItems });

    document.addEventListener("keydown", handleShiftPress);
    document.addEventListener("keyup", handleShiftUp);

    return function clear() {
      stopTrackMouse();
      document.removeEventListener("keydown", handleShiftPress);
      document.removeEventListener("keyup", handleShiftUp);
    };
  }, []);

  useEffect(() => {
    stopTrackMouse();

    const extra = {};

    setState({
      ...stateRef.current,
      items: listWithChildren(itemsFromProps, childrenProp),
      dragItem: null,
      isDirty: false,
      ...extra,
    });
  }, [props]);

  // ––––––––––––––––––––––––––––––––––––
  // Click handlers or event handlers
  // ––––––––––––––––––––––––––––––––––––
  const onDragStart = (e: DragEvent, item: Item) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    elementRef.current = closest(e.target, ".nestable-item");
    elementHeightRef.current = elementRef.current?.clientHeight;

    setState({
      ...stateRef.current,
      dragItem: item,
      itemsOld: stateRef.current.items,
    });

    startTrackMouse();
    onMouseMove(e, item);
  };

  const onMouseEnter = (e: MouseEvent, item: Item) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (dragItem) {
      if (dragItem.id === item.id) return;

      const pathFrom = getPathById(dragItem.id);
      const pathTo = getPathById(item.id);

      // if collapsed by default
      // and move last (by count) child
      // remove parent node from list of open nodes
      let collapseProps = {};
      if (collapsed && pathFrom.length > 1) {
        const parent = getItemByPath(pathFrom.slice(0, -1));
        if (parent) {
          if (parent[childrenProp].length === 1) {
            collapseProps = onToggleCollapse(parent, true);
          }
        }
      }

      moveItem({ currentDragItem: dragItem, pathFrom, pathTo }, collapseProps);
    }
  };

  const getItemOptions = () => {
    const { renderCollapseIcon, handler } = props;

    const {
      current: { isShiftPressed },
    } = stateRef;

    return {
      dragItem,
      childrenProp,
      renderItem,
      renderCollapseIcon,
      handler,
      onDragStart,
      onMouseEnter,
      isCollapsed,
      isShiftPressed,
      onToggleCollapse,
      onClick: handleItemClick,
    };
  };

  const {
    listContainer = "",
    list = "",
    listItem = "",
    listItemContent = "",
    listItemBackground = "",
    nestedList = "",
    listItemOnMultiselect = "",
    listItemSelected = "",
  } = classNames;

  const itemClassNames = {
    listItem,
    listItemContent,
    listItemBackground,
    nestedList,
    listItemOnMultiselect,
    listItemSelected,
  };

  const renderDragLayer = () => {
    let el = null;

    if (dragItem) {
      el = document.querySelector(
        `.nestable-${group} .nestable-item-${dragItem.id}`
      );
    }

    let listStyles: any = {};
    if (el) {
      listStyles.width = el.clientWidth;
    }
    if (elCopyStylesRef.current) {
      listStyles = {
        ...listStyles,
        ...elCopyStylesRef.current,
      };
    }

    const options = getItemOptions();
    return (
      <div className="nestable-drag-layer">
        <ol className="nestable-list" style={listStyles}>
          {dragItem && (
            <NestableItem
              item={dragItem}
              options={options}
              isDisabled={isDisabled}
              classNames={itemClassNames}
              // select work only in multiselect mode
              selectedItems={{}}
              isSelected={false}
              isCopy
            />
          )}
        </ol>
      </div>
    );
  };

  const options = getItemOptions();
  const {
    current: { selectedItems },
  } = stateRef;

  return (
    <div
      ref={rootContainerRef}
      className={cn(listContainer, "nestable", `nestable-${group}`, {
        "is-drag-active": !!dragItem,
      })}
    >
      <ol className={cn(list, "nestable-list nestable-group")}>
        {items.map((item, i) => (
          <NestableItem
            key={item.id}
            index={i}
            item={item}
            options={options}
            classNames={itemClassNames}
            isDisabled={isDisabled}
            isSelected={!!selectedItems[item.id]}
            selectedItems={selectedItems}
          />
        ))}
      </ol>

      {dragItem && renderDragLayer()}
    </div>
  );
};

export default Nestable;
