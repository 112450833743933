import { useMouseMove, useMouseMoveEvent } from './mouse-event-hooks';
import { Mops } from '../types';

// eslint-disable-next-line import/prefer-default-export
export const useWithDown = ({
  handleMouse,
  handleMouseEvent,
  handleMouseDown,
  hideGuides,
  scale,
  setAdditionalAngle,
  setInitialPosition,
  setInitialRotation,
  setPosition,
  setRotation,
  parentRotation,
}: {
  handleMouse: (position: Mops.PositionModel) => Mops.PositionModel,
  handleMouseDown: () => void,
  handleMouseEvent: (event: MouseEvent, init?: boolean) => { rotation: Mops.RotationModel, deg: number } | false,
  hideGuides: (guides?: string[]) => void,
  setInitialPosition: (initialPosition: Mops.PositionModel) => void,
  setInitialRotation: (initialRotation: Mops.RotationModel) => void,
  setAdditionalAngle: (angle: Mops.RotationModel) => void,
  setPosition: (position: Mops.PositionModel) => void,
  setRotation: (rotation: Mops.RotationModel) => void,
  scale: number,
  parentRotation: Mops.RotationModel
}) => {
  const [isDown, setDown] = useMouseMove({
    onMouseUp: position => {
      const withSnapLogic = handleMouse(position);
      setPosition(withSnapLogic);
      setInitialPosition(withSnapLogic);
      hideGuides();
    },
    onMouseMove: position => {
      const withSnapLogic = handleMouse(position);
      setPosition(withSnapLogic);
    },
    onMouseDown: () => {
      handleMouseDown();
    },
    scale,
    parentRotation,
  });

  const [isRotationDown, handleRotationDown] = useMouseMoveEvent(
    (event: MouseEvent) => {
      const d = handleMouseEvent(event);
      if (d) {
        setRotation(d.rotation);
        setInitialRotation(d.rotation);
      }
    },
    (event: MouseEvent) => {
      const d = handleMouseEvent(event);
      if (d) {
        setRotation(d.rotation);
      }
    },
    (event: MouseEvent) => {
      const d = handleMouseEvent(event, true);
      if (d) {
        setRotation(d.rotation);
        setInitialRotation(d.rotation);
        setAdditionalAngle({ x: 0, y: 0, z: d.deg });
      }
    },
  );

  return {
    handleRotationDown,
    isDown,
    isRotationDown,
    setDown,
  };
};
