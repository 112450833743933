import React from 'react';
import cn from 'classnames';

import './Icon.css';

interface IconProps {
  className: string,
}

const Icon = ({ className, ...restProps }: IconProps) => (
  <i className={cn('nestable-icon', className)} {...restProps} />
);
export default Icon;
