import React from 'react';
import { Mops } from '../types';

// eslint-disable-next-line import/prefer-default-export
export const listenRR = ({
  currentSize,
  currentPosition,
  currentRotation,
  loaded,
  isDown,
  isTopDown,
  isRightDown,
  isBottomDown,
  isLeftDown,
  isTopLeftDown,
  isTopRightDown,
  isBottomLeftDown,
  isBottomRightDown,
  handleDrag,
  handleResize,
  isRotationDown,
  handleRotate,
}: {
  currentSize: Mops.SizeModel,
  currentPosition: Mops.PositionModel,
  currentRotation: Mops.RotationModel,
  handleDrag: () => void,
  handleResize: () => void,
  handleRotate: () => void,
  loaded: boolean,
  isDown: boolean,
  isTopDown: boolean,
  isRightDown: boolean,
  isBottomDown: boolean,
  isLeftDown: boolean,
  isTopLeftDown: boolean,
  isTopRightDown: boolean,
  isBottomLeftDown: boolean,
  isBottomRightDown: boolean,
  isRotationDown: boolean,
}) => React.useEffect(() => {
  if (loaded) {
    if (isDown) {
      handleDrag();
    } else if (
      [
        isTopDown,
        isRightDown,
        isBottomDown,
        isLeftDown,
        isTopLeftDown,
        isTopRightDown,
        isBottomLeftDown,
        isBottomRightDown,
      ].filter(Boolean).length
    ) {
      handleResize();
    } else if (isRotationDown) {
      handleRotate();
    }
  }
}, [currentSize, currentPosition, currentRotation]);
