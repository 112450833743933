import React from 'react';
import { Box } from './box';
import { GuidesConsumer } from './guides';
import { Mops } from './types';

// eslint-disable-next-line import/prefer-default-export
export const GuidedBox: React.ForwardRefExoticComponent<
React.PropsWithoutRef<Mops.BoxProps> & React.RefAttributes<HTMLElement>
> = React.forwardRef((
  { children, ...props },
  ref,
) => (
  <GuidesConsumer>
    {context => (
      <Box {...props} {...context} ref={ref}>
        {children}
      </Box>
    )}
  </GuidesConsumer>
));
