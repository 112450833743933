import React from "react";
import cn from "classnames";

import Icon from "../Icon";
import { ItemProps, Item } from "./interface";

const NestableItem = (props: ItemProps) => {
  const renderDefaultCollapseIcon = ({ isCollapsed }: any) => (
    <Icon
      className={cn("nestable-item-icon", {
        "icon-plus-gray": !!isCollapsed,
        "icon-minus-gray": !isCollapsed,
      })}
    />
  );

  const {
    item,
    isCopy,
    options,
    index,
    classNames,
    isSelected,
    selectedItems,
    isDisabled,
    isNested = false,
    isParentSelected = false,
    parent,
    classNames: {
      listItem,
      listItemContent,
      listItemBackground,
      nestedList,
      // listItemOnMultiselect,
      // listItemSelected,
    },
  } = props;
  const {
    dragItem,
    renderItem,
    handler,
    childrenProp,
    isShiftPressed,
    onClick,
    renderCollapseIcon = renderDefaultCollapseIcon,
  } = options;

  const isCollapsed = options.isCollapsed(item);
  const isDragging = !isCopy && dragItem && dragItem.id === item.id;
  const hasChildren = item[childrenProp] && item[childrenProp].length > 0;

  let rowProps: any = {
    className: cn(listItemContent, "nestable-item-name"),
  };
  let handlerProps = {};
  let Handler;

  const handleClick = () => {
    if (isShiftPressed) {
      if (!isNested || (isNested && !isParentSelected)) {
        onClick(item, index);
      } else if (isNested && isParentSelected) {
        onClick(parent?.item, parent?.index);
      }
    }
  };

  if (!isCopy) {
    if (dragItem) {
      rowProps = {
        ...rowProps,
        onMouseEnter: (e: MouseEvent) => options.onMouseEnter(e, item),
      };
    } else {
      handlerProps = {
        ...handlerProps,
        draggable: isDisabled ? false : true,
        onDragStart: (e: MouseEvent) => options.onDragStart(e, item),
      };
    }
  }

  if (handler) {
    Handler = (
      <span className="nestable-item-handler" {...handlerProps}>
        {handler}
      </span>
    );
  } else {
    rowProps = {
      ...rowProps,
      ...handlerProps,
    };
  }

  const baseClassName = `nestable-item${isCopy ? "-copy" : ""}`;

  if (isShiftPressed) {
    rowProps = {
      ...rowProps,
      draggable: false,
      onDragStart: () => {},
      onClick: handleClick,
      // className: cn(listItemContent, "nestable-item-name", {
      //   // [`${baseClassName}--on-multiselect`]: (isNested && !isParentSelected && !isSelected) || !isSelected,
      //   // [`${baseClassName}--selected`]: isSelected || isParentSelected,
      //   // [listItemOnMultiselect]: (isNested && !isParentSelected && !isSelected) || !isSelected,
      //   // [listItemSelected]: isSelected || isParentSelected,
      // }),
    };
  }
  const collapseIcon = hasChildren ? (
    <span
      role="button"
      tabIndex={0}
      onClick={() => options.onToggleCollapse(item)}
      onKeyDown={() => {}}
    >
      {renderCollapseIcon({ isCollapsed })}
    </span>
  ) : null;

  const itemProps = {
    className: cn(listItem, baseClassName, `${baseClassName}-${item.id}`, {
      [`${baseClassName}--is-dragging`]: isDragging,
      [`${baseClassName}--with-children`]: hasChildren,
      [`${baseClassName}--children-open`]: hasChildren && !isCollapsed,
      [`${baseClassName}--children-collapsed`]: hasChildren && isCollapsed,
      [`${baseClassName}--selected`]: isSelected || isParentSelected
    }),
  };

  const commonProps = {
    options,
    isCopy,
    classNames,
    selectedItems,
  };

  const content = renderItem({
    item,
    collapseIcon,
    handler: Handler,
    index,
  });

  if (!content) return null;

  return (
    <li {...itemProps}>
      {isDragging && (
        <div className={cn(listItemBackground, "nestable-item-background")} />
      )}
      <div {...rowProps}>{content}</div>

      {hasChildren && !isCollapsed && (
        <ol className={cn(nestedList, "nestable-list")}>
          {item[childrenProp].map((currentItem: Item, i: number) => (
            <NestableItem
              key={currentItem.id}
              index={i}
              item={currentItem}
              isNested
              isDisabled={isDisabled}
              isSelected={!!selectedItems[currentItem.id] || isSelected}
              isParentSelected={isSelected}
              parent={{ item, index }}
              {...commonProps}
            />
          ))}
        </ol>
      )}
    </li>
  );
};

export default NestableItem;
