import React from "react";
import { Mops } from "../types";
import {
  coordinatesToDeg,
  cos,
  getBoundingBox,
  sin,
  to360,
  withRotation,
} from "../utils";

/**
 * Mousemove hook
 * @param {Mops.MouseHandler} onMouseUp
 * @param {Mops.MouseHandler} onMouseMove
 * @param {function} onMouseDown
 * @param {number} scale
 * @param {Mops.RotationModel} [rotation]
 * @param {Mops.RotationModel} [parentRotation]
 */
export const useMouseMove = ({
  onMouseUp,
  onMouseMove,
  // @ts-ignore
  onMouseDown,
  scale,
  rotation,
  parentRotation,
}: {
  onMouseUp: Mops.MouseHandler;
  onMouseMove: Mops.MouseHandler;
  // @ts-ignore
  onMouseDown: () => void;
  scale: number;
  rotation?: Mops.RotationModel;
  parentRotation: Mops.RotationModel;
}) => {
  const [isDown, setDown] = React.useState(false);
  const [initialPosition, setInitialPosition] =
    React.useState<Mops.PositionModel>({
      x: 0,
      y: 0,
    });

  const getRotatedPosition = React.useCallback(
    (event: MouseEvent): Mops.PositionModel => {
      const angle = 360 - parentRotation.z;
      const newX = event.clientX - initialPosition.x;
      const newY = event.clientY - initialPosition.y;

      const newPosition = {
        x: (newX * cos(angle) - newY * sin(angle)) / scale,
        y: (newX * sin(angle) + newY * cos(angle)) / scale,
      };

      // chooses between rotate and drag events
      return rotation
        ? withRotation(newPosition.x, newPosition.y, rotation.z)
        : newPosition;
    },
    [initialPosition, scale, rotation, parentRotation]
  );

  const handleMouseUp = React.useCallback(
    (event: MouseEvent) => {
      if (isDown) {
        event.preventDefault();
        const rotatedPosition = getRotatedPosition(event);
        setDown(false);
        onMouseUp(rotatedPosition, event.altKey, event.shiftKey, event);
      }
    },
    [setDown, onMouseUp, getRotatedPosition]
  );

  const handleMouseMove = React.useCallback(
    (event: MouseEvent) => {
      if (isDown) {
        event.preventDefault();
        const rotatedPosition = getRotatedPosition(event);

        onMouseMove(rotatedPosition, event.altKey, event.shiftKey, event);
      }
    },
    [onMouseMove, getRotatedPosition]
  );

  React.useEffect(() => {
    document.addEventListener("mouseleave", handleMouseUp);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseleave", handleMouseUp);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseUp]);

  React.useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseMove]);

  const handleDown = (event: Mops.InternalMouseEvent<HTMLElement>) => {
    if (
      (event.target as HTMLElement).tagName !== "INPUT" &&
      event.button !== 2 &&
      !event.internalStopPropagation
    ) {
      event.internalStopPropagation = true;
      onMouseDown();
      setDown(true);
      setInitialPosition({
        x: event.clientX,
        y: event.clientY,
      });
    }
  };

  return [isDown, handleDown] as [
    boolean,
    (e: Mops.InternalMouseEvent<HTMLElement>) => void
  ];
};
/**
 *
 * @param onMouseUp
 * @param onMouseMove
 * @param onMouseDown
 */
export const useMouseMoveEvent = (
  onMouseUp: (event: MouseEvent) => void,
  onMouseMove: (event: MouseEvent) => void,
  onMouseDown: (event: MouseEvent) => void
) => {
  const [isDown, setDown] = React.useState(false);
  const handleMouseUp = React.useCallback(
    (event: MouseEvent) => {
      if (isDown) {
        event.preventDefault();
        setDown(false);
        onMouseUp(event);
      }
    },
    [onMouseUp, setDown]
  );
  const handleFocus = React.useCallback(() => {
    setDown(false);
  }, [setDown]);
  const handleMouseMove = React.useCallback(
    (event: MouseEvent) => {
      if (isDown) {
        event.preventDefault();
        onMouseMove(event);
      }
    },
    [onMouseMove]
  );

  React.useEffect(() => {
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleFocus);
    return () => {
      document.removeEventListener("focus", handleFocus);
      document.removeEventListener("blur", handleFocus);
    };
  }, [handleFocus]);

  React.useEffect(() => {
    document.addEventListener("mouseleave", handleMouseUp);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseleave", handleMouseUp);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseUp]);

  React.useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseMove]);

  const handleDown = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (
        (event.target as HTMLElement).tagName !== "INPUT" &&
        event.button !== 2
      ) {
        event.preventDefault();
        setDown(true);
        onMouseDown(event as unknown as MouseEvent);
      }
    },
    [onMouseDown, setDown]
  );

  return [isDown, handleDown] as [
    boolean,
    (event: React.MouseEvent<HTMLElement>) => void
  ];
};

export const useHandleMouseEvent = ({
  additionalAngle,
  contentRef,
  initialRotation,
  isRotatable,
}: {
  additionalAngle: Mops.RotationModel;
  initialRotation: Mops.RotationModel;
  contentRef: React.RefObject<HTMLElement>;
  isRotatable: boolean;
}) =>
  React.useCallback(
    (
      event: MouseEvent,
      init?: boolean
    ): { rotation: Mops.RotationModel; deg: number } | false => {
      if (!isRotatable || !contentRef || !contentRef.current) {
        return false;
      }

      const { clientX, clientY } = event;
      const { left, top, width, height } =
        contentRef.current.getBoundingClientRect();
      const pointer = {
        x: clientX - left,
        y: clientY - top,
      };
      const center = {
        x: width / 2,
        y: height / 2,
      };
      const deg = coordinatesToDeg(pointer, center);
      const newRotationZ = to360(initialRotation.z + (deg - additionalAngle.z));
      const newRotation = (state: Mops.RotationModel) => ({
        x: state.x,
        y: state.y,
        z: init
          ? to360(initialRotation.z)
          : event.shiftKey
          ? Math.round(newRotationZ / 15) * 15
          : newRotationZ,
      });
      return {
        deg, // @ts-ignore TODO there's smth wrong with this part, it returns function, but (almost) all code expects object
        rotation: newRotation,
      };
    },
    [contentRef, initialRotation, isRotatable]
  );
export const useHandleMouse = ({
  addGuides,
  currentRotation,
  currentSize,
  initialPosition,
  shouldSnap,
  guideRequests,
  guides,
  hideGuides,
  removeGuides,
  showGuides,
  updateGuide,
}: Mops.GuidesContext & {
  currentRotation: Mops.RotationModel;
  currentSize: Mops.SizeModel;
  initialPosition: Mops.PositionModel;
  shouldSnap?: Mops.SnapHandler[];
}) =>
  React.useCallback(
    ({ x, y }): Mops.PositionModel => {
      const newPosition = {
        x: initialPosition.x + x,
        y: initialPosition.y + y,
      };

      // TODO refactor this
      // @ts-ignore
      const result = shouldSnap.reduce(
        // @ts-ignore
        (model: Mops.PositionModel, snapHandler) => ({
          ...(snapHandler(
            {
              position: newPosition,
              rotation: currentRotation,
              size: getBoundingBox({
                ...currentSize,
                angle: currentRotation.z,
              }),
            },
            {
              addGuides,
              guideRequests,
              guides,
              hideGuides,
              removeGuides,
              showGuides,
              updateGuide,
            },
            model
          ) as Mops.SnapHandler),
        }),
        newPosition as Mops.PositionModel
      ) as Mops.PositionModel;

      return result;
    },
    [
      currentSize,
      currentRotation,
      initialPosition,
      shouldSnap,
      showGuides,
      hideGuides,
    ]
  );
